<template>
  <div>
    <div class="operationControl flexdc" style="align-items: flex-start;">
      <div class="searchbox" style="margin-bottom: 5px">
        <div title="学员姓名" class="searchboxItem ci-full">
          <span class="itemLabel">学员姓名:</span>
          <el-input
            v-model="userName"
            type="text"
            size="small"
            clearable
            placeholder="请输入学员姓名"
          />
        </div>
        <div title="是否联系" class="searchboxItem ci-full">
          <span class="itemLabel">是否联系:</span>
          <el-select
            v-model="contacted"
            placeholder="请选择"
            clearable
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div title="工种" class="searchboxItem ci-full">
          <span class="itemLabel">工种:</span>
          <el-cascader
            :options="miniCourseTypeList"
            v-model="courseType"
            :props="miniProps"
            size="small"
            clearable
          ></el-cascader>
        </div>
        <div title="等级" class="searchboxItem ci-full">
          <span class="itemLabel">等级:</span>
          <el-select
            v-model="trainLevelId"
            placeholder="请选择"
            clearable
            size="small"
          >
            <el-option
              v-for="item in trainLevelList"
              :key="item.trainLevelId"
              :label="item.trainLevelName"
              :value="item.trainLevelId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox">
        <div title="报名时间" class="searchboxItem ci-full">
          <span class="itemLabel">报名时间:</span>
          <el-date-picker
            class="listInput"
            clearable
            size="small"
            v-model="createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>

        <div class="btnBox" style="margin-left: 20px">
          <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          <el-button class="bgc-bv" round @click="exportCourse()"
            >导出</el-button
          >
          <el-button class="bgc-bv" round @click="exportInformation()"
            >导出证件信息</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
            @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="学员姓名"
            align="center"
            show-overflow-tooltip
            prop="userName"
          />
          <el-table-column
            label="性别"
            align="center"
            prop="sex"
            show-overflow-tooltip
            min-width="60"
          >
            <template slot-scope="scope">
              {{ scope.row.sex == "1" ? "男" : "女" }}
            </template>
          </el-table-column>
          <el-table-column
            label="身份证号"
            align="center"
            show-overflow-tooltip
            prop="idcard"
          />
          <el-table-column
            label="工作单位"
            align="center"
            show-overflow-tooltip
            prop="workUnit"
          />
          <el-table-column
            label="职业工种"
            align="center"
            show-overflow-tooltip
            prop="courseTypeName"
          />
          <el-table-column
            label="申报等级"
            align="center"
            show-overflow-tooltip
            prop="trainLevelName"
          />
          <el-table-column
            label="文化程度"
            align="center"
            show-overflow-tooltip
            prop="educationLevel"
          >
           <template slot-scope="scope">
              {{ $setDictionary('EDUCATION',scope.row.educationLevel)}}
            </template>
          </el-table-column>
          <el-table-column label="户口所在地" align="center" prop="idCardAddress" show-overflow-tooltip/>
          <el-table-column label="是否联系" align="center" width="100px">
            <div slot-scope="scope" class="flexcc">
              <el-switch
                :width="20"
                v-model="scope.row.contacted"
                @change="
                  getSwitchUsable(scope.row.contacted, scope.row.compApplyId)
                "
              ></el-switch>
              <span>{{ scope.row.contacted ? "是" : "否" }}</span>
            </div>
          </el-table-column>
          <el-table-column label="备注" align="center" prop="remark" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="!scope.row.orderShow">
                <span> {{ scope.row.remark }}</span>
                <i
                  class="el-icon-edit"
                  v-show="scope.row.showInput"
                  @click="changeOrder(scope.row)"
                ></i>
              </div>
              <el-input
                v-else
                v-model="scope.row.remark"
                maxlength="200"
                size="small"
                @blur="
                  () => {
                    editRowRemark(scope.row);
                  }
                "
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="报名时间" align="center" prop="createTime">
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      delectId: "",
      activeName: "first",
      userName: "",
      courseType: "",
      trainLevelId: "",
      createTime: "",
      contacted: "",
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      miniCourseTypeList: [],
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
      trainLevelList: [],
    };
  },
  created() {
    this.getcourseTypeArrData();
    this.getTrainLevel();
  },
  computed: {},
  methods: {
    //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { showStatus: true,roleId:0,hiddenState:10 },
        3000,
        true,
        2
      ).then((ret) => {
        this.miniCourseTypeList = this.delchild(ret.data);
      });
    },
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    getTrainLevel() {
      this.$post("/sys/category/qb-train-level/list", {}, 3000, true, 2).then(
        (ret) => {
          this.trainLevelList = ret.data;
        }
      );
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        contacted: this.contacted,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.courseType) {
        params.courseType = this.courseType;
      }
      if (this.trainLevelId) {
        params.trainLevelId = this.trainLevelId;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }

      this.doFetch(
        {
          url: "/miniapp/special/apply/comp/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    exportCourse() {
      const params = {
        contacted: this.contacted,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }
      this.$post(
        "/miniapp/special/apply/comp/export",
        params,
        3000,
        true,
        2
      ).then((ret) => {
        window.open(ret.data);
      });
    },
    exportInformation() {
      const params = {
        contacted: this.contacted,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }
      this.$post(
        "/miniapp/special/apply/information/export",
        params,
        3000,
        true,
        2
      ).then((ret) => {
        window.open(ret.message);
      });
    },
    // 是否联系
    getSwitchUsable(keyId, enabled) {
      this.$post(
        "/miniapp/special/apply/modifyIsContact",
        {
          contacted: keyId,
          compApplyId: enabled,
        },
        3000,
        false,
        2
      )
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
    handleListDataBack(data) {
      this.tableData = [];
      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        this.tableData.push(el);
      });
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      console.log(row, column, cell, event);
      if (column.label == "备注") {
        row.showInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      ;
      row.orderShow = !row.orderShow;
    },
    //输入框失去焦点 保存修改
    editRowRemark(row) {
      const params = {
        remark: row.remark,
        compApplyId: row.compApplyId,
      };
      this.$post(
        "/miniapp/special/apply/modifyRemark",
        params,
        3000,
        true,
        2
      ).then((res) => {
        ;
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getData(-1);
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }
  .el-dialog__title {
    color: #333;
  }
  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
</style>
