<template>
  <div>
    <div class="operationControl">
      <div class="searchbox" style="margin-bottom: 5px">
        <div title="学员姓名" class="searchboxItem ci-full">
          <span class="itemLabel">学员姓名:</span>
          <el-input
            v-model="userName"
            type="text"
            size="small"
            clearable
            placeholder="请输入学员姓名"
          />
        </div>
        <div title="是否联系" class="searchboxItem ci-full">
          <span class="itemLabel">是否联系:</span>
          <el-select v-model="contacted" placeholder="请选择" clearable size="small">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div title="课程名称" class="searchboxItem ci-full">
          <span class="itemLabel">课程名称:</span>
          <el-input
            v-model="courseName"
            type="text"
            size="small"
            clearable
            placeholder="请输入课程名称"
          />
        </div>
        <div title="报名时间" class="searchboxItem ci-full">
          <span class="itemLabel">报名时间:</span>
          <el-date-picker
            class="listInput"
            clearable
            size="small"
            v-model="createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>

        <el-button
          style="margin-left: 20px"
          class="bgc-bv"
          round
          @click="getData()"
          >查询</el-button
        >
        <div class="btnBox" style="margin-left: 20px">
          <el-button class="bgc-bv" round @click="exportCourse()"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
           @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="学员姓名"
            align="center"
            show-overflow-tooltip
            prop="realname"
          />
          <el-table-column
                label="性别"
                align="center"
                prop="sex"
                show-overflow-tooltip
                min-width="60"
              >
                <template slot-scope="scope">
                  {{ scope.row.sex == "1" ? "男" : "女" }}
                </template>
              </el-table-column>
          <el-table-column
                label="身份证号"
                align="center"
                prop="idcard"
                show-overflow-tooltip
                min-width="150"
              />
          <el-table-column
            label="工作单位"
            align="center"
            show-overflow-tooltip
            prop="company"
          />
          <el-table-column
            label="所属岗位"
            align="center"
            show-overflow-tooltip
            prop="post"
          />
          <el-table-column
            label="联系方式"
            align="center"
            show-overflow-tooltip
            prop="phone"
          />
          <el-table-column
            label="所属地区"
            align="center"
            show-overflow-tooltip
            prop="areaName"
          />
          <el-table-column label="当前地址" align="center" prop="address" show-overflow-tooltip/>
          <el-table-column label="报名课程" align="center" prop="courseName" show-overflow-tooltip />
           <el-table-column
                label="是否联系"
                align="center"
                width="100px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-switch
                    :width="20"
                    v-model="scope.row.isContact"
                    @change="
                      getSwitchUsable(
                        scope.row.isContact,
                        scope.row.courseApplyId
                      )
                    "
                  ></el-switch>
                  <span>{{ scope.row.isContact ? "是" : "否" }}</span>
                </div>
              </el-table-column>
          <el-table-column label="备注" align="center" prop="remark" show-overflow-tooltip>
              <template slot-scope="scope">
                  <div v-if="!scope.row.orderShow">
                    <span> {{ scope.row.remark }}</span>
                    <i
                      class="el-icon-edit"
                      v-show="scope.row.showInput"
                      @click="changeOrder(scope.row)"
                    ></i>
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.remark"
                     maxlength="200"
                    size="small"
                    @blur="
                      () => {
                        editRowOrder(scope.row);
                      }
                    "
                  ></el-input>
                </template>
          </el-table-column>
          <el-table-column label="报名时间" align="center" prop="applyTime" minWidth="150">
            <template slot-scope="scope">
              {{ scope.row.applyTime | moment }}
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      userName: "",
      courseName: "",
      createTime: "",
      contacted: "",
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
    };
  },
  computed: {},
  methods: {
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        contacted: this.contacted,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }

      this.doFetch(
        {
          url: "/miniapp/special/apply/course/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    exportCourse() {
         const params = {
        contacted: this.contacted,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }
        this.$post('/miniapp/special/apply/course/export',params,3000,true,2).then(ret => {
           if (ret.status == "0") {
          let url = ret.data || "";
          window.open(url);
        }
        })
    },
     getSwitchUsable(contacted, courseApplyId) {
      this.$post(
        "/miniapp/special/course/apply/modifyIsContact",
        {
          contacted,
          courseApplyId,
        },
        3000,
        false,
        2
      )
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
      handleListDataBack(data) {
      this.tableData = [];
      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        this.tableData.push(el);
      });
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      console.log(row, column, cell, event);
      if (column.label == "备注") {
        row.showInput = true;
      }
    },
     // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      ;
      row.orderShow = !row.orderShow;
    },
    //输入框失去焦点 保存修改
    editRowOrder(row) {
      const params = {
        remark: row.remark,
        courseApplyId: row.courseApplyId,
      };
      this.$post("/miniapp/special/course/apply/modifyIsContact", params, 3000, true, 2).then((res) => {
        ;
        this.$message({
          type:'success',
          message:'修改成功'
        })
        this.getData(-1);
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }
  .el-dialog__title {
    color: #333;
  }
  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
</style>
